<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card shadow="always">
      <div slot="header">
        <span v-if="id" class="maintitle">編輯組織</span>
        <span v-else class="maintitle">新增組織</span>
      </div>

      <!--組織設定-->
      <el-row>
        <span class="title">組織設定</span>

        <el-switch
          style="margin-left: 30px"
          v-model="company.status"
          :active-value="'ENABLED'"
          :inactive-value="'DISABLED'"
          active-text="啟用"
          inactive-text="停用"
          active-color="#13ce66"
          :disabled="statusDisabled"
        >
        </el-switch>

        <span v-if="statusDisabled" style="margin-left: 20px; color: red"
          >所有必填欄位完整後才可啟用</span
        >

        <el-divider class="divide"></el-divider>

        <el-form label-position="left" :model="company" label-width="180px">
          <el-row :gutter="15" class="topDist">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="組織名稱" required>
                <el-input
                  v-model="company.name"
                  placeholder="請輸入組織名稱"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="6"> </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="公司統編" required>
                <el-input
                  v-model="company.ubn"
                  placeholder="請輸入統編或系統自動編號"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-button class="tagColor" @click="getCustomUbn"
              >系統編號</el-button
            >
          </el-row>

          <el-row :gutter="15">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="組織信箱" required>
                <el-input
                  v-model="company.email"
                  placeholder="請輸入組織信箱"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="營利屬性" required>
                <el-radio-group
                  v-model="company.isNgo"
                  class="columnDist2"
                  @change="changeIsNgo"
                >
                  <el-radio :label="false">營利</el-radio>
                  <el-radio :label="true">非營利</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="銷售活動" required>
                <el-radio-group
                  v-model="company.isSalable"
                  class="columnDist2"
                  @change="changeIsSalable"
                >
                  <el-radio :label="true">有銷售活動</el-radio>
                  <el-radio :label="false">無銷售活動</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :offset="1" :sm="10">
              <el-form-item
                class="columnDist7"
                label="是否開啟每日會籍人數統計"
                required
              >
                <el-radio-group
                  v-model="company.datePlanStatistics"
                  class="columnDist2"
                >
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>

      <!--樂易捐方案-->
      <el-row>
        <span class="title">樂易捐方案</span>
        <el-divider class="divide"></el-divider>

        <el-table
          :data="company.companyPlanList"
          border
          stripe
          style="width: 100%"
          class="topDist2"
        >
          <el-table-column
            prop="planName"
            label="方案"
            width="250"
            align="center"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.planId"
                placeholder="請選擇"
                @change="
                  (planId) => {
                    selectedPlan(scope.row, planId);
                  }
                "
              >
                <el-option
                  v-for="item in planList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column
            prop="startDatetimeStr"
            label="開始日期"
            width="250"
            align="center"
          >
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.startDatetimeStr"
                type="date"
                placeholder="請選擇日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                default-time="['00:00:00']"
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column
            prop="endDatetimeStr"
            label="結束日期"
            width="250"
            align="center"
          >
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.endDatetimeStr"
                type="date"
                placeholder="請選擇日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                default-time="['00:00:00']"
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column prop="fee" label="月費" width="180" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fee">
                <div slot="append">TWD</div>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column
            prop="handlingCharge"
            label="交易手續費"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.handlingCharge">
                <div slot="append">%</div>
              </el-input>
            </template>
          </el-table-column>

          <el-table-column prop="salesName" label="業務" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.salesName"> </el-input>
            </template>
          </el-table-column>
        </el-table>

        <el-button type="warning" @click="addPlan" class="btn-pos"
          >新增</el-button
        >
      </el-row>

      <!--管理員帳號設定-->

      <el-row v-if="!id">
        <span class="title">管理員帳號設定</span>
        <el-divider class="divide"></el-divider>

        <el-row>
          <el-form label-position="left" :model="company" label-width="180px">
            <el-row :gutter="15" class="topDist">
              <el-col :offset="1" :sm="12">
                <el-form-item class="columnDist7" label="姓名" required>
                  <el-input
                    v-model="company.adminName"
                    placeholder="請輸姓名"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :sm="6"> </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :offset="1" :sm="12">
                <el-form-item class="columnDist7" label="電子信箱" required>
                  <el-input
                    v-model="company.adminEmail"
                    placeholder="請輸入電子信箱"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </el-row>

      <!--收款模式-->
      <el-row
        v-if="company.isNgo && company.isSalable"
        style="padding-top: 40px"
      >
        <span class="title">收款模式</span>
        <el-divider class="divide"></el-divider>

        <el-form label-position="left" :model="company" label-width="180px">
          <el-row :gutter="15" class="topDist">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="收據" required>
                <el-row>
                  <el-checkbox v-model="company.isReceiptDuctable"
                    >可開立可扣抵收據</el-checkbox
                  >
                  <el-checkbox v-model="company.isReceiptDisductable"
                    >可開立不可扣抵收據</el-checkbox
                  >
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15" class="topDist">
            <el-col :offset="1">
              <el-form-item class="columnDist7" label="收據開立方式" required>
                <el-table
                  ref="checkRcIssueTable"
                  :data="receiptIssueData"
                  border
                  stripe
                  empty-text="暫無數據"
                  style="width: 25%"
                  type="selection"
                  :row-key="rowKeyReceiptIssue"
                  highlight-current-row
                  @selection-change="handleSelectRcIssueChange"
                >
                  <el-table-column
                    type="selection"
                    min-width="5%"
                    header-align="center"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="name"
                    label="開立方式"
                    min-width="25%"
                    header-align="center"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="code"
                    label="預設"
                    min-width="10%"
                    header-align="center"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-radio
                        v-model="defaultRcIssue"
                        :label="scope.row.code"
                        style="padding-left: 0px;!important"
                        @change="changeRadioRcIssue(scope.row.code)"
                        :disabled="changeRadioRcIssueDisable(scope.row.code)"
                        >{{ "" }}</el-radio
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15" class="topDist">
            <el-col :offset="1" :sm="12">
              <el-form-item
                class="columnDist7"
                label="捐贈碼（上傳國稅局匯出用)"
              >
                <el-input
                  v-model="company.govDonateCode"
                  placeholder="請輸入捐贈碼"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>

      <el-row
        v-if="company.isNgo == false || company.isSalable"
        style="padding-top: 40px"
      >
        <span class="title">發票設定</span>
        <el-divider class="divide"></el-divider>

        <el-form label-position="left" :model="company" label-width="180px">
          <el-row :gutter="15" class="topDist">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="發票開立" required>
                <el-radio-group
                  v-model="company.isInvoiceable"
                  class="columnDist2"
                >
                  <el-radio :label="true">開立發票</el-radio>
                  <el-radio :label="false">不開立發票</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <div v-if="company.isInvoiceable">
            <el-row :gutter="15">
              <el-col :offset="1" :sm="12">
                <el-form-item class="columnDist7" label="平台">
                  <el-radio-group
                    v-model="company.invoiceProvider"
                    class="columnDist2"
                  >
                    <el-radio label="EzPay">藍新發票</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="company.invoiceProvider == 'EzPay'">
              <el-form-item class="columnDist" label="MerchantId" required>
                <el-input
                  v-model="company.ezPayMerchantId"
                  placeholder="請輸入MerchantId"
                  style="width: 30%"
                  required
                ></el-input>
              </el-form-item>

              <el-form-item class="columnDist" label="Key" required>
                <el-input
                  v-model="company.ezPayKey"
                  placeholder="請輸入Key"
                  style="width: 30%"
                  required
                ></el-input>
              </el-form-item>

              <el-form-item class="columnDist" label="Iv" required>
                <el-input
                  v-model="company.ezPayIv"
                  placeholder="請輸入Iv"
                  style="width: 30%"
                  required
                ></el-input>
              </el-form-item>

              <el-form-item class="columnDist" label="">
                <el-button
                  type="success"
                  @click="testExternalSysLogin"
                  :loading="testLoading"
                  >測試連線</el-button
                >
              </el-form-item>
            </div>

            <!--預設捐贈單位-->
            <el-form-item
              class="columnDist"
              label="預設捐贈單位"
            ></el-form-item>
            <el-form-item class="columnDist" label="受贈單位名稱" required>
              <el-input
                v-model="company.donatoName"
                placeholder="請輸入受贈單位名稱"
                style="width: 30%"
                required
              ></el-input>
            </el-form-item>

            <el-form-item class="columnDist" label="捐贈碼" required>
              <el-input
                v-model="company.donatoCode"
                placeholder="請輸入捐贈碼"
                style="width: 30%"
                required
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-row>

      <el-row style="padding-top: 40px">
        <span class="title">外部系統設定</span>
        <el-divider class="divide"></el-divider>

        <el-form label-position="left" :model="company" label-width="180px">
          <el-row :gutter="15" class="topDist">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="會員更新網址">
                <el-input
                  v-model="company.payerUpdateUrl"
                  placeholder="請輸入會員更新網址"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="會員更新網址金鑰">
                <el-input
                  v-model="company.payerUpdateKey"
                  placeholder="請輸入會員更新網址金鑰"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-form label-position="left" :model="company" label-width="180px">
          <el-row :gutter="15" class="topDist">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="會籍更新網址">
                <el-input
                  v-model="company.payerMembershipUpdateUrl"
                  placeholder="請輸入會籍更新網址"
                  required
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :offset="1" :sm="12">
              <el-form-item class="columnDist7" label="會籍更新網址金鑰">
                <el-input
                  v-model="company.payerMembershipUpdateKey"
                  placeholder="請輸入會籍更新網址金鑰"
                  required
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>

      <el-form>
        <el-form-item>
          <div class="dialog-footer">
            <el-button @click="cancel">取消</el-button>
            <el-button v-if="id" type="primary" @click="update">更新</el-button>
            <el-button v-else type="primary" @click="add">新增</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import API from "../../api";
import Utils from "../../utils";
import { Message } from "element-ui";
import Vue from "vue";
import VueTelInput from "luciditv-vue-tel-input";
import _ from "lodash";
import api from "../../api";
Vue.use(VueTelInput);

export default {
  name: "CustomerEdit",

  data() {
    return {
      isLoading: false,
      testLoading: false,

      id: this.$route.query.id,
      company: {
        name: null,
        ubn: null,
        email: null,
        status: "DISABLED",
        isNgo: null,
        isSalable: null,
        datePlanStatistics: null,
        //樂易捐方案
        companyPlanList: [
          {
            planId: null,
            planName: null,
            startDatetimeStr: null,
            endDatetimeStr: null,
            fee: null,
            handlingCharge: null,
            salesName: null,
          },
        ],
        adminName: null,
        adminEmail: null,
        isReceiptDuctable: false,
        isReceiptDisductable: false,
        receiptIssueList: [],
        defaultRcIssue: null,
        govDonateCode: null,

        isInvoiceable: null,
        invoiceProvider: null,

        ezPayMerchantId: null,
        ezPayKey: null,
        ezPayIv: null,

        donatoName: null,
        donatoCode: null,

        payerUpdateUrl: null,
        payerUpdateKey: null,
        payerMembershipUpdateUrl: null,
        payerMembershipUpdateKey: null,
      },

      planList: [],

      // 圖片新增
      contentImage: [],
      contentDialogImageUrl: null,
      contentDialogVisible: false,
      contentDisabled: false,
      contentShowUpload: false,

      isActive: false,

      //收據開立方式
      receiptIssueData: [
        { code: "BY_TIME", name: "單筆開立" },
        { code: "BY_YEAR", name: "年度開立" },
        { code: "UPLOAD_NTB", name: "上傳國稅局不提供紙本" },
        { code: "UNWANTED", name: "不需收據" },
      ],
      multipleSelectRcIssue: [],
      defaultRcIssue: null,
    };
  },

  created() {
    this.isLoading = true;

    // 參考網址：https://ithelp.ithome.com.tw/articles/10204718 => Promise.all 方法
    Promise.all([
      this.recoverRcIssue(),
      this.getPlanList(),
      this.id ? this.openWindowData() : 0,
    ])
      .then((res) => {
        if (res[0] && res[1]) {
          this.handleRadioRcIssueData();
          this.$nextTick(function () {
            this.changeRadioRcIssue(this.company.defaultRcIssue);
          });
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },

  computed: {
    statusDisabled() {
      if (Utils.Check.isNull(this.company.name)) return true;
      if (Utils.Check.isNull(this.company.ubn)) return true;
      if (Utils.Check.isNull(this.company.email)) return true;
      if (this.company.isNgo === null) return true;
      if (this.company.isSalable === null) return true;
      if (this.company.datePlanStatistics === null) return true;
      if (this.company.isInvoiceable === null) return true;

      if (Utils.Check.isNull(this.company.adminName) && !this.id) return true;
      if (Utils.Check.isNull(this.company.adminEmail) && !this.id) return true;

      if (this.company.isNgo && this.company.isSalable) {
        if (
          !this.company.isReceiptDuctable &&
          !this.company.isReceiptDisductable
        )
          return true;

        if (this.multipleSelectRcIssue.length <= 0) return true;
      }

      if (this.company.isInvoiceable) {
        if (this.company.invoiceProvider == "EzPay") {
          if (Utils.Check.isNull(this.company.ezPayMerchantId)) return true;
          if (Utils.Check.isNull(this.company.ezPayKey)) return true;
          if (Utils.Check.isNull(this.company.ezPayIv)) return true;
        }

        if (Utils.Check.isNull(this.company.donatoName)) return true;
        if (Utils.Check.isNull(this.company.donatoCode)) return true;
      }
    },
  },

  watch: {
    statusDisabled: function (newVal) {
      if (newVal) this.company.status = "DISABLED";
    },
  },

  methods: {
    //輸入捐贈碼0.5秒後, 確認此捐贈碼是否正確
    donateCodeChange: _.debounce(function (value) {
      API.opendata.getDonateOrgUnitByCode(value).then((res) => {
        if (!res.data) {
          Message({
            message: `無此捐贈碼:${value}`,
            type: "warning",
          });
        }
      });
    }, 500),

    //打受贈單位會直接帶出捐贈碼
    querySearchAsync(queryString, cb) {
      if (queryString) {
        API.opendata.queryDonateOrgUnit(queryString).then((res) => {
          cb(res.data);
        });
      }
    },
    handleSelect(companyDonato, item) {
      companyDonato.code = item.donateCode;
    },

    selectedPlan(row, planId) {
      const selectedPlan = this.planList.find((it) => it.id == planId);
      if (selectedPlan) {
        row.planName = selectedPlan.name;
        row.startDatetimeStr = selectedPlan.startDatetimeStr;
        row.endDatetimeStr = selectedPlan.endDatetimeStr;
        row.fee = selectedPlan.fee;
        row.handlingCharge = selectedPlan.handlingCharge;
        row.salesName = selectedPlan.salesName;
      }
    },

    openWindowData() {
      return new Promise((resolve, reject) => {
        API.customer
          .queryById(this.id)
          .then((res) => {
            Object.assign(this.company, res.data);
            // 若捐贈碼列表為空，預設空的捐贈碼列表提供填寫
            // if (this.company.companyDonatoList.length == 0) {
            //   this.company.companyDonatoList.push({
            //     code: null,
            //     name: null,
            //     seq: 100,
            //   });
            // }
            // 當 組織 logoUrl 不為空，則設定圖片上去
            if (!Utils.Check.isNull(this.company.logoUrl)) {
              this.contentImage = [{ url: this.company.logoUrl }];
              this.contentShowUpload = true;
            }
            // 設定組織 收據開立方式預設值
            if (!Utils.Check.isNull(this.company.defaultRcIssue)) {
              this.defaultRcIssue = this.company.defaultRcIssue;
            }
            if (
              this.company.receiptIssueList &&
              this.company.receiptIssueList.length !== 0
            ) {
              this.multipleSelectRcIssue = this.company.receiptIssueList;
            }
            this.isLoading = false;
            resolve(true);
          })
          .catch((e) => {
            console.log(`error`);
            this.$message.error("取值失敗!");
            this.isLoading = false;
            reject(false);
            this.$router.go(-1);
          });
      });
    },

    changeIsNgo() {
      if (!this.company.isNgo) {
        this.company.isSalable = true;
      }
    },
    // 組織 銷售活動改變時，若選到 非銷售，發票預設設定為false
    changeIsSalable() {
      if (!this.company.isSalable) {
        this.company.isInvoiceable = false;
      }
    },

    cancel() {
      // 編輯結束,回到上一頁
      this.$router.go(-1);
    },

    // 產生系統編號
    getCustomUbn() {
      this.isLoading = true;
      API.customer
        .getCustomUbn()
        .then((newP) => {
          // 1. 取得系統編號
          this.company.ubn = newP.data.customCompanyUbn;
          this.isLoading = false;
        })
        .catch((err) => {
          // 1. loading 結束
          this.isLoading = false;
        });
    },

    add() {
      // 1. 防呆
      if (Utils.Check.isNull(this.company.name)) {
        Message({
          message: "組織名稱必填!",
          type: "warning",
        });
        return;
      }

      if (!Utils.Check.checkEmail(this.company.email)) {
        Message({
          message: "組織信箱格式錯誤!",
          type: "warning",
        });
        return;
      }

      if (Utils.Check.isNull(this.company.adminName)) {
        Message({
          message: "管理員帳號-姓名必填!",
          type: "warning",
        });
        return;
      }

      if (Utils.Check.isNull(this.company.adminEmail)) {
        Message({
          message: "管理員帳號-電子信箱必填!",
          type: "warning",
        });
        return;
      }

      if (!Utils.Check.checkEmail(this.company.adminEmail)) {
        Message({
          message: "管理員帳號-電子信箱格式錯誤!",
          type: "warning",
        });
        return;
      }

      // console.log(this.company);

      // // 開立發票為fasle 捐贈碼 內容首次登入，不需要填寫，若是資料為受贈單位名稱及代碼皆為NULL，預設將捐贈碼列表清空陣列
      // if (!this.company.isInvoiceable) {
      //   if (this.company.companyDonatoList.length == 1) {
      //     let companyDonato = this.company.companyDonatoList[0];
      //     if (
      //       Utils.Check.isNull(companyDonato.name) &&
      //       Utils.Check.isNull(companyDonato.code)
      //     )
      //       this.company.companyDonatoList = [];
      //   }
      // }
      // // 取代電話空白
      // if (this.company.accountApi.phone) {
      //   this.company.accountApi.phone = this.company.accountApi.phone.replace(
      //     /\s/g,
      //     ""
      //   );
      // }

      // // 檢查捐贈碼欄位內容
      // if (
      //   this.company.companyDonatoList !== undefined &&
      //   this.company.companyDonatoList.length > 0
      // ) {
      //   let Values = [];
      //   for (let cd of this.company.companyDonatoList) {
      //     if (Utils.Check.isNull(cd.name)) {
      //       Message({
      //         message: "受贈單位名稱必填!",
      //         type: "warning",
      //       });
      //       return;
      //     }
      //     if (Utils.Check.isNull(cd.code)) {
      //       Message({
      //         message: "捐贈碼必填!",
      //         type: "warning",
      //       });
      //       return;
      //     }

      //     if (Utils.Check.isNull(cd.seq)) {
      //       Message({
      //         message: "順序必填!",
      //         type: "warning",
      //       });
      //       return;
      //     }

      //     // check 自訂選項的值
      //     if (Values.includes(cd.code)) {
      //       Message({
      //         message: `${cd.code}捐贈碼已重複!`,
      //         type: "warning",
      //       });
      //       return;
      //     } else {
      //       Values.push(cd.code);
      //     }
      //   }
      // } else {
      //   // 檢查 若選擇開立發票，必需要設定捐贈碼列表至少一筆
      //   if (this.company.isInvoiceable) {
      //     Message({
      //       message: "捐贈碼欄位必填",
      //       type: "warning",
      //     });
      //     return;
      //   }
      // }

      // // 2. 設定網路商店 - 支付方式
      // if (this.multipleSelection.length <= 0) {
      //   Message({
      //     message: "支付方式至少選擇一種",
      //     type: "warning",
      //   });
      //   return;
      // } else {
      //   this.company.storeList[0].paymentToolApi = this.multipleSelection;
      // }

      // 3. 設定 收據開立方式
      if (this.company.isNgo && this.company.isSalable) {
        if (this.multipleSelectRcIssue <= 0) {
          this.company.receiptIssueList = [];
          this.company.defaultRcIssue = null;
        } else {
          this.company.receiptIssueList = this.multipleSelectRcIssue;
          this.company.defaultRcIssue = this.defaultRcIssue;
        }
      }

      this.company.companyPlanList = this.company.companyPlanList.filter(
        (item) => item.planId
      );

      // 4. 儲存/更新
      this.isLoading = true;
      API.customer
        .addCustomer(this.company)
        .then((newP) => {
          Message({
            message: "新增成功!",
            type: "success",
          });
          this.isLoading = false;
          // 新增結束,回到上一頁
          this.$router.go(-1);
        })
        .catch((err) => {
          // 1. loading 結束
          this.isLoading = false;
        });
    },

    update() {
      // 1. 防呆
      if (Utils.Check.isNull(this.company.name)) {
        Message({
          message: "組織名稱必填!",
          type: "warning",
        });
        return;
      }

      if (!Utils.Check.checkEmail(this.company.email)) {
        Message({
          message: "組織信箱格式錯誤!",
          type: "warning",
        });
        return;
      }

      // 2. 設定 收據開立方式
      if (this.company.isNgo && this.company.isSalable) {
        if (this.multipleSelectRcIssue <= 0) {
          this.company.receiptIssueList = [];
          this.company.defaultRcIssue = null;
        } else {
          this.company.receiptIssueList = this.multipleSelectRcIssue;
          this.company.defaultRcIssue = this.defaultRcIssue;
        }
      }

      this.company.companyPlanList = this.company.companyPlanList.filter(
        (item) => item.planId
      );

      // 3. 儲存/更新
      this.isLoading = true;
      API.customer
        .update(this.id, this.company)
        .then((newP) => {
          Message({
            message: "更新成功!",
            type: "success",
          });
          this.isLoading = false;
          // 編輯結束,回到上一頁
          this.$router.go(-1);
        })
        .catch((err) => {
          // 1. loading 結束
          this.isLoading = false;
        });
    },

    // 上傳圖片
    handleContentImgRemove(file, fileList) {
      // console.log("HI~ handleContentImgRemove");
      // console.log(file);
      // console.log(fileList);
      this.$refs.contentImg.clearFiles();
      this.contentShowUpload = false;
      this.company.logoUrl = null;
    },
    handleContentImgCardPreview(file) {
      // console.log("HI~ handleContentImgCardPreview");
      this.contentDialogImageUrl = file.url;
      this.contentDialogVisible = true;
    },
    handleContentImgChange(file, fileList) {
      // console.log("HI~ handleContentImgChange");
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";

      if (!isJPG && !isPNG) {
        this.$message.error("上傳圖片只能是JPG或PNG格式!");
        this.$refs.contentImg.clearFiles();
        return;
      }

      const limitSzie = 1 * 1024 * 1024;
      if (file.size > limitSzie) {
        this.$message.error("The max size is 1MB!");
        this.$refs.contentImg.clearFiles();
        return;
      }

      // check only and upload one picture
      if (fileList.length >= 1) {
        this.contentShowUpload = true;
      }
      // TODO: if status is ready, need to call api to push gcp upload url
      if (file.status === "ready") {
        const fileType = file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        Promise.all([this.getBase64(file.raw)]).then((res) => {
          // console.log(res[0]);
          if (res[0]) {
            const params = res[0].split(",");
            if (params.length > 0) {
              const queryData = {
                mimnType: `${file.raw.type}`,
                content: `${params[1]}`,
              };
              API.customer.uploadImage({ queryData }).then((res) => {
                if (res.data.success) {
                  this.company.logoUrl = res.data.logoUrl;
                }
              });
            }
          }
        });
      }
    },
    // 檔案轉Base64
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    /** 收據開立方式 **/
    recoverRcIssue() {
      // 1. 清除 預設勾選的支付工具
      this.multipleSelectRcIssue = [];

      return new Promise(function (resolve, reject) {
        resolve(true);
      });
    },
    rowKeyReceiptIssue(row) {
      return row.code;
    },
    // 選擇check 收據開立方式列表
    handleSelectRcIssueChange(val) {
      this.multipleSelectRcIssue = val;
      // console.log(this.multipleSelectRcIssue);

      // 若選擇預設值對應收據開立方式被反勾，則將已選擇預設值移除
      if (
        !JSON.stringify(this.multipleSelectRcIssue).includes(
          this.defaultRcIssue
        )
      ) {
        this.defaultRcIssue = null;
      }
    },
    // 選擇radio button 收據開立方式預設值
    changeRadioRcIssue(val) {
      this.defaultRcIssue = val;
      if (this.multipleSelectRcIssue.length <= 0) {
        // 若沒有選擇 開立方式列表，無法選擇預設值
        this.defaultRcIssue = null;
      } else {
        // 若不在選擇 開立方式列表，無法選擇預設值
        if (!JSON.stringify(this.multipleSelectRcIssue).includes(val)) {
          this.defaultRcIssue = null;
        }
      }
    },
    // 控制radio button 是否 disable狀態
    changeRadioRcIssueDisable(val) {
      if (!JSON.stringify(this.multipleSelectRcIssue).includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    // 处理表格数据,已还款的自动选上
    handleRadioRcIssueData() {
      // 設定組織 收據開立方式
      if (
        !Utils.Check.isNull(this.company.receiptIssueList) &&
        this.company.receiptIssueList.length > 0
      ) {
        // 將已被勾選支付工具codes轉成集合
        const codes = this.company.receiptIssueList.map((item) => {
          return item.code;
        });
        // 等畫面渲染結束後，在執行$nextTick()
        this.$nextTick(function () {
          this.receiptIssueData.forEach((item) => {
            if (codes.includes(item.code)) {
              this.$refs["checkRcIssueTable"].toggleRowSelection(item, true);
            }
          });
        });
      }
    },

    //取得方案清單
    getPlanList() {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        API.companyPlan
          .getPlanList()
          .then((res) => {
            // 結果
            this.planList = res.data;
            resolve(true);
          })
          .catch((e) => {
            console.log(`store getList error`);
            Message({
              message: "取值失敗!",
              type: "error",
              duration: 3 * 1000,
            });
            reject(false);
          });
      });
    },

    //新增方案
    addPlan() {
      this.company.companyPlanList.push({
        planId: null,
        planName: null,
        startDatetimeStr: null,
        endDatetimeStr: null,
        fee: null,
        handlingCharge: null,
        salesName: null,
      });
    },

    testExternalSysLogin() {
      if (Utils.Check.isNull(this.company.ezPayMerchantId)) {
        Message({
          message: "請輸入MerchantId!",
          type: "warning",
        });
        return;
      }

      if (Utils.Check.isNull(this.company.ezPayKey)) {
        Message({
          message: "請輸入Key!",
          type: "warning",
        });
        return;
      }

      if (Utils.Check.isNull(this.company.ezPayIv)) {
        Message({
          message: "請輸入Iv!",
          type: "warning",
        });
        return;
      }

      this.testLoading = true;
      api.customer
        .testNewebpay(this.company)
        .then((res) => {
          if (res.data.testConnection) {
            Message({
              message: "連線成功!",
              type: "success",
            });
          } else {
            Message({
              message: "連線失敗!",
              type: "warning",
            });
          }

          this.testLoading = false;
        })
        .catch((err) => {
          Message({
            message: "測試錯誤!",
            type: "error",
          });

          this.testLoading = false;
        });
    },
  },
};
</script>

<style scoped>
/**新增組織字體*/
.maintitle {
  font-size: 18px;
  font-weight: bold;
}

/**區塊title字體*/
.title {
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 20px;
}

/**區塊title抬頭下的分隔線*/
.divide {
  margin-top: 10px;
  width: 700px;
  position: absolute;
  border-top: 2px solid #bdbdbd;
}

/**基本資料與左邊圖片位置對齊*/
.topDist {
  margin-top: 45px;
}

/**樂易捐方案table位置*/
.topDist2 {
  margin-top: 25px;
  margin-left: 0px;
}

/**發票設定位置*/
.columnDist {
  font-weight: bold;
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/**radio-button位置*/
.columnDist2 {
  margin-left: 5px;
  margin-top: 10px;
}

/**發票設定位置*/
.columnDist3 {
  font-weight: bold;
  margin-left: 30px;
  font-size: 16px;
}

/**預設捐贈單位＆收款模式位置*/
.columnDist4 {
  font-weight: bold;
  margin-left: 30px;
  margin-top: 20px;
}

/**部分label位置*/
.columnDist5 {
  font-weight: bold;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 0px;
}

/**API串接金鑰位置*/
.columnDist6 {
  font-weight: bold;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
}

/**部分label字體*/
.columnDist7 {
  font-weight: bold;
}

/**計費字體＆位置*/
.columnDist8 {
  font-weight: bold;
  margin-left: 30px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}

/**新增按鈕*/
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

/**新增方案按鈕*/
.btn-pos {
  margin: 20px;
}

.square >>> .hide .el-upload--picture-card,
.widescreen >>> .hide .el-upload--picture-card {
  display: none;
}
.square >>> .el-upload.el-upload--picture-card {
  width: 18vw;
  height: 18vw;
  line-height: 18vw;
  margin-top: 30px;
}
.widescreen >>> .el-upload.el-upload--picture-card {
  width: 32vw;
  height: 18vw;
  line-height: 18vw;
}
.square >>> .el-upload-list__item {
  width: 18vw;
  height: 18vw;
}
.widescreen >>> .el-upload-list__item {
  width: 32vw;
  height: 18vw;
}
.imgFitCover {
  width: 100%;
  height: 100%;
}

::v-deep .el-form-item__label {
  margin-bottom: 0px;
  padding: 0px;
}

/**系統編號*/
.tagColor {
  color: #ffffff;
  background-color: #527bae;
}
</style>
